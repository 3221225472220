import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    username: '',
    currentStep: '',
    befStep: '',
    login: '',
    loginOptions: '',
    session: ''
  },
  mutations: {
    setUsername(state, data) { state.username = data },
    setCurrentStep(state, data) { state.currentStep = data },
    setBefStep(state, data) { state.befStep = data },
    setLogin(state, data) { state.login = data },
    setLoginOptions(state, data) { state.loginOptions = data },
    setSession(state, data) { state.session = data }
  },
  getters: {
    getUsername(state) { return state.username },
    getCurrentStep(state) { return state.currentStep },
    getBefStep(state) { return state.befStep },
    getLogin(state) { return state.login },
    getLoginOptions(state) { return state.loginOptions },
    getSession(state) { return state.session }
  }
})

export default store