<template>
  <div class="home h-100">
    <div class="container-fluid h-100">
      <div class="row h-100 d-flex justify-content-center">
        <div class="col-md-7 bg-dark h-100 d-none d-md-block px-0">
          <img src="https://auth.x-orsystem.com/assets/banners/banner.jpg" class="banner">
        </div>
        <div class="col-md-5 h-100 d-flex align-items-center block-shadow">
              
          <router-view></router-view>
  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

html, body { height: 100%; }
.banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.block-shadow {
  box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
}
</style>