import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // HOME
  { path: '/', component: () => import('@/views/Home.vue') },

  // MAINTENANCE
  { path: '/recovery/password', component: () => import('@/views/recovery/Password.vue') },
  
  // REDIRECT
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
